import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useIntl } from "hooks";

import { SEO, Text, Flex, Box, Button, BackgroundImage } from "components";

const Background = styled.div`
  z-index: -1;
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
`;

const Overlay = styled.div`
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
`;

const IndexPage = () => {
  const locale = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: contentfulHome {
            background {
              fluid(maxWidth: 2000) {
                sizes
                src
                srcSet
              }
            }
          }
          nl: contentfulHome(node_locale: { regex: "/nl/" }) {
            button
            title
          }
          en: contentfulHome(node_locale: { regex: "/en/" }) {
            button
            title
          }
        }
      `}
      render={data => (
        <>
          <SEO title="Home" />
          <Background>
            <BackgroundImage fluid={data.placeholderImage.background.fluid} />
            <Overlay />
          </Background>
          {/* <Center> */}
          <Flex
            mt="380px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            px={{ _: "16px", md: "160px" }}
          >
            <Box
              textAlign={{ _: "center", sm: "right" }}
              ml={{ _: "0", md: "200px" }}
              maxWidth={{ _: "100%", sm: "70%", lg: "60%" }}
            >
              <Text color="#fff" as="h1">
                {data[locale].title}
              </Text>
              <Button mx="auto" link to="/contact" mt="40px" secondary>
                {data[locale].button}
              </Button>
            </Box>
          </Flex>
        </>
      )}
    />
  );
};

export default IndexPage;
